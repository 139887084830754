<h1>Alias Game</h1>

<ng-container *ngIf="page === 'welcome'">
  <div class="menu-buttons">
    <button (click)="page = 'create session'">Neue Session erstellen</button>
    <button (click)="getSessions()">Einer Session beitreten</button>
  </div>
</ng-container>

<div class="menu-container" *ngIf="page === 'create session'">
  <h2>Erstelle eine neue Session</h2>

  <div class="form-group">
    <label>Wer bist du?</label>
    <input [(ngModel)]="session.creator" type="text" maxlength="16" placeholder="Mr(s). Anonymous" />
  </div>
  <div class="form-group">
    <label>Gib einen Session-Namen ein</label>
    <input [(ngModel)]="session.name" type="text" maxlength="16" placeholder="Friday Fun" />
  </div>
  <div class="form-group">
    <label>Größe des Spielfelds</label>
    <select [(ngModel)]="selectedBoardSize">
      <option *ngFor="let size of boardSizes">{{ size }}</option>
    </select>
  </div>
  <div class="form-group">
    <label>Wähle ein Thema</label>
    <select [(ngModel)]="selectedTheme">
      <option *ngFor="let theme of themes">{{ theme.name }}</option>
    </select>
  </div>
  <div class="form-group">
    <label>Name des 1. Teams</label>
    <input [(ngModel)]="session.teamOneName" type="text" maxlength="16" placeholder="A" />
  </div>
  <!-- <div class="form-group">
    <label>Farbe des 1. Teams</label>
    <input class="color-picker" [(colorPicker)]="session.teamOneColor" [style.background]="session.teamOneColor" />
  </div> -->
  <div class="form-group">
    <label>Name des 2. Teams</label>
    <input [(ngModel)]="session.teamTwoName" type="text" maxlength="16" placeholder="B" />
  </div>
  <br />
  <!-- <div class="form-group">
    <label>Farbe des 2. Teams</label>
    <input class="color-picker" [(colorPicker)]="session.teamTwoColor" [style.background]="session.teamTwoColor" />
  </div> -->
  <div class="form-group">
    <button (click)="createSession()">Spiel erstellen</button>
    <button (click)="page = 'welcome'">Abbrechen</button>
  </div>
</div>

<div class="menu-container" *ngIf="page === 'join session'">
  <h2>Nimm an einer Session teil</h2>

  <table>
    <thead>
      <tr>
        <td>Session</td>
        <td>Erstellt von</td>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let session of sessions2Join"
        [ngClass]="{ active: selectedSession2Join?.name === session.name }"
        (click)="selectSession2Join(session)"
      >
        <td>{{ session.name }}</td>
        <td>{{ session.creator }}</td>
      </tr>
    </tbody>
  </table>
  <br />

  <div class="form-group" *ngIf="selectedSession2Join">
    <label>Wer bist du?</label>
    <input [(ngModel)]="participant" type="text" maxlength="16" placeholder="Mr(s). Anonymous" />
  </div>

  <div class="form-group" *ngIf="selectedSession2Join">
    <label>In welches Team gehst du?</label>
    <select [(ngModel)]="selectedTeam2Join" (change)="updateTeamMembers()">
      <option *ngFor="let team of selectedSession2Join.teams">{{ team.name }}</option>
    </select>
  </div>
  <br *ngIf="!selectedTeam2Join" />

  <div class="form-group" *ngIf="selectedTeam2Join">
    <p>In Team {{ selectedTeam2Join }} {{ teamMembers }}.</p>
  </div>

  <div class="form-group">
    <button (click)="joinSession()" [disabled]="!selectedSession2Join || !selectedTeam2Join">
      Beitreten
    </button>
    <button (click)="page = 'welcome'">Abbrechen</button>
  </div>
</div>

<div class="game" *ngIf="page === 'play'">
  <app-players
    [activeSession]="activeSession"
    (activePlayer)="iAmActivePlayer = $event"
    (leave)="page = 'welcome'"
  ></app-players>
  <app-board
    [activeSession]="activeSession"
    [participant]="participant"
    [iAmActivePlayer]="iAmActivePlayer"
  ></app-board>
  <div class="ui-right">
    <app-code-sheet [activeSession]="activeSession" *ngIf="iAmActivePlayer"></app-code-sheet>
    <button *ngIf="!iAmActivePlayer && !activeSession.started && noActivePlayer" (click)="requestActivePlayer()">
      Ich möchte Begriffe erklären
    </button>
    <app-history [activeSession]="activeSession"></app-history>
  </div>

  <app-winner *ngIf="winnerTeam !== ''" [winnerTeam]="winnerTeam" (reset)="resetSession()"></app-winner>
</div>
