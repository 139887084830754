<div class="row" *ngFor="let cardRow of cards; let y = index">
  <app-card
    (click)="selectCard(x, y)"
    *ngFor="let card of cardRow; let x = index"
    [word]="card.word"
    [color]="card.color"
    [uncovered]="card.uncovered"
    [width]="cardSize.width"
    [height]="cardSize.height"
    [flipCard]="false"
    [iAmActivePlayer]="_iAmActivePlayer"
    [allowExchangeCards]="!_activeSession.started"
    (exchangeWord)="exchangeTerm($event)"
  ></app-card>
</div>
