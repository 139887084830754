<table *ngIf="_activeSession">
  <thead>
    <tr>
      <td>Team</td>
      <td>Begriff</td>
      <td>Treffer</td>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let event of historyEvents">
      <td>
        {{ event.team }}
      </td>
      <td>{{ event.description }} {{ event.amount }}</td>
      <td>
        <div *ngIf="event.teamA > 0" class="color" [ngStyle]="{ background: '#c22b0c' }">{{ event.teamA }}</div>
        <div *ngIf="event.teamB > 0" class="color" [ngStyle]="{ background: '#0b6bca' }">{{ event.teamB }}</div>
        <div *ngIf="event.neutral > 0" class="color" [ngStyle]="{ background: '#dddddd' }">
          {{ event.neutral }}
        </div>
        <div *ngIf="event.black > 0" class="color" [ngStyle]="{ background: '#222222' }">{{ event.black }}</div>
      </td>
    </tr>
  </tbody>
</table>
