<div class="row" *ngFor="let colorRow of colors">
  <div
    class="color"
    *ngFor="let color of colorRow"
    [ngStyle]="{ width: colorSize + 'px', height: colorSize + 'px', background: color }"
  ></div>
</div>

<ng-container *ngIf="!_activeSession.description.accepted">
  <div class="form-group">
    <label *ngIf="activeTeam === user.team"><b>Dein Begriff:</b></label>
    <label *ngIf="activeTeam !== user.team"
      ><b>{{ activeOpponent }} schreibt:</b></label
    >
  </div>
  <div class="form-group">
    <input [(ngModel)]="term" maxlength="24" [disabled]="activeTeam !== user.team" />
    <input type="number" [(ngModel)]="amount" min="0" max="8" [disabled]="activeTeam !== user.team" />
  </div>
  <div class="form-group active" *ngIf="_activeSession.description.denied && activeTeam === user.team">
    Dein Begriff wurde abgelehnt.
  </div>
  <div class="form-group">
    <button *ngIf="activeTeam === user.team" (click)="requestDescription()">Anfragen</button>
    <button class="positive" *ngIf="activeTeam !== user.team" (click)="acceptDescription()">Okay</button>
    <button class="negative" *ngIf="activeTeam !== user.team" (click)="denyDescription()">Nein</button>
  </div>
  <br />
</ng-container>
