<div class="container">
  <div class="session">{{ _activeSession?.name }}</div>

  <div class="active-team" *ngIf="activeTeam !== ''">
    Team {{ activeTeam }} ist dran.
  </div>

  <div class="description" *ngIf="_activeSession.description.term !== '' && _activeSession.description.accepted">
    <i>{{ _activeSession.description.term }} {{ _activeSession.description.amount }}</i>
  </div>

  <div class="team" *ngFor="let team of _activeSession?.teams">
    <div class="team-info">
      <div class="team-color" [ngStyle]="{ background: team.color }"></div>
      <div class="team-meta">
        <div class="team-meta-line">
          <b>Team {{ team.name }}</b>
        </div>
        <div class="team-meta-line" *ngIf="team.remainingCards > 1">
          Noch {{ team.remainingCards }} Karten übrig
        </div>
        <div class="team-meta-line" *ngIf="team.remainingCards === 1">
          Noch eine Karte übrig
        </div>
        <div class="team-meta-line" *ngIf="team.remainingCards <= 0">
          Gewonnen!
        </div>
      </div>
    </div>

    <ul>
      <li *ngFor="let player of team.players">
        <span [ngClass]="{ 'active-player': player.active }">
          <i class="fa fa-user-circle-o" aria-hidden="true"></i>
          <span *ngIf="team.name === user.team && player.name === user.player"
            >&nbsp;<b>{{ player.name }}</b></span
          >
          <span *ngIf="team.name !== user.team || player.name !== user.player"
            >&nbsp;{{ player.name }}</span
          >
        </span>
      </li>
    </ul>
  </div>

  <!-- <button class="active-player-request" *ngIf="!iAmActivePlayer" (click)="requestActivePlayer()">Ich möchte Begriffe erklären.</button> -->

  <button
    class="positive"
    *ngIf="_activeSession && !_activeSession.started && iAmActivePlayer"
    (click)="startSession()"
  >
    Spiel beginnen
  </button>
  <button *ngIf="_activeSession && _activeSession.started && iAmActivePlayer" (click)="nextRound()">
    Nächste Runde
  </button>
  <button class="negative leave-session" (click)="leaveSession()">
    Session verlassen
  </button>
</div>
